import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';

import {
  Badge,
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  Link,
  Radio,
} from '@zealy/design-system';
import { CheckLine, ChevronDownLine, MapViewFilled } from '@zealy/icons';
import { useAdminQuestboard, useQuests } from '@zealy/queries';

import { ResponsivePopover } from '#components/ResponsivePopover';
import { useCommunitiesFlags } from '#context/FeatureFlags';

import { useError } from '../../../../FormError.context';
import { access } from './TextField';

export interface SelectQuestFieldProps {
  name: string;
  namespace: string;
}

export const SelectQuestField = ({ name, namespace }: SelectQuestFieldProps) => {
  const { ff_better_invites } = useCommunitiesFlags(['ff_better_invites']);
  const t = useTranslations('quest');
  const { subdomain, questId } = useParams<{ subdomain: string; questId?: string }>();

  const { data: questboardData } = useAdminQuestboard({
    subdomain,
    filters: [
      {
        f: 'status',
        v: ['active'],
        c: 'IS',
        n: 'AND',
      },
    ],
  });
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const context = useError(name);

  const error = access(`${name}.message`, errors) ?? context.error;
  // @ts-ignore
  const label = t(`type.${namespace}.label`);

  const data = useMemo(
    () =>
      (questboardData ?? [])
        .map(({ quests, id: categoryId, title }) => ({
          value: categoryId,
          label: title,
          children: quests
            .filter(({ id }) => id !== questId)
            .map(quest => ({
              value: quest.id,
              label: quest.name,
              published: quest.published,
            })),
        }))
        .filter(({ children }) => children.length > 0),
    [questboardData, questId],
  );

  const [actualValue] = watch(name) ?? [''];

  const selectedQuestName = useMemo(
    () =>
      questboardData
        ?.find(({ quests }) => quests.some(({ id }) => id === actualValue))
        ?.quests.find(({ id }) => id === actualValue)?.name,
    [actualValue, questboardData],
  );

  if (!ff_better_invites.enabled) return null;

  return (
    <FormControl
      name={name}
      label={label}
      state={error ? 'error' : 'default'}
      hint={
        error ??
        t.rich('type.invites.fields.mandatoryQuests.hint', {
          docs: children => (
            <Link underlined size="sm" target="_blank" href="/docs/">
              {children}
            </Link>
          ),
        })
      }
      optional
    >
      <ResponsivePopover
        trigger={
          <Button
            variant={'muted'}
            mutedText={!selectedQuestName}
            className="bg-transparent border-select justify-start"
            rightIcon={<ChevronDownLine />}
          >
            <span className="flex-1 text-start">
              {selectedQuestName ?? t('type.invites.fields.mandatoryQuests.none')}
            </span>
          </Button>
        }
        popoverProps={{
          className: '!p-0 z-[50] h-[400px] overflow-hidden w-[600px] max-w-screen',
          align: 'start',
          variant: 'secondary',
          sideOffset: 4,
        }}
        drawerClassName="!px-0 pb-0"
      >
        <Command className="bg-transparent">
          <CommandInput placeholder={t('placeholder')} autoFocus />
          <CommandList className="h-full">
            <CommandEmpty>{t('type.invites.fields.mandatoryQuests.empty')}</CommandEmpty>
            {data?.map(({ label, children, value }) => (
              <CommandGroup heading={label} key={value}>
                {children?.map(item => (
                  <CommandItem
                    value={item.label}
                    key={item.value}
                    onSelect={() => {
                      setValue(name, actualValue === item.value ? [] : [item.value], {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }}
                    icon={<CheckLine className={actualValue === item.value ? '' : 'opacity-0'} />}
                    className="relative"
                  >
                    {item.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            ))}
          </CommandList>
        </Command>
      </ResponsivePopover>
    </FormControl>
  );
};
