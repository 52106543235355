'use client';

import React from 'react';

import { Dialog, DialogContent } from '@zealy/design-system';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '#components/ImageCarousel';

import { FileType, getFileType } from './FileType.utils';

export interface SubmissionCardDialogProps {
  images: Array<string>;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  initialIndex?: number;
}

export const SubmissionCardDialog = ({
  images,
  initialIndex,
  open,
  onOpenChange,
}: SubmissionCardDialogProps) => {
  const nextRef = React.useRef<HTMLButtonElement>(null);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className="bg-tertiary h-[100dvh] w-[100dvw] max-w-6xl max-h-[900px] flex"
        onOpenAutoFocus={() => {
          if (nextRef.current) {
            setTimeout(() => {
              nextRef.current?.focus();
            }, 100);
          }
        }}
      >
        <Carousel
          className="w-full"
          opts={{
            startIndex: initialIndex,
          }}
        >
          <CarouselContent className="h-full min-h-0">
            {images.map((img, i) => (
              <CarouselItem key={img}>
                {getFileType(img) === FileType.IMAGE ? (
                  <img
                    src={img}
                    alt=""
                    key={img}
                    className="h-full w-full object-center object-contain"
                  />
                ) : (
                  <video
                    src={img}
                    controls
                    className="h-full w-full object-center object-contain"
                  />
                )}
              </CarouselItem>
            ))}
          </CarouselContent>
          {images.length > 1 && (
            <>
              <CarouselPrevious />
              <CarouselNext ref={nextRef} />
            </>
          )}
        </Carousel>
      </DialogContent>
    </Dialog>
  );
};
