'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import { cn } from '../../utils/cn';
import { formControlStyles } from '../FormControl/FormControl.styles';
import { TagAnatomy as Anatomy, Tag } from '../Tag';
import { tagStyles as styles } from '../Tag/Tag.styles';
import { TaskCard } from '../TaskCard';
import { containerStyle, descriptionStyle, infoStyle, titleStyle } from './Poll.styles';
export const Poll = ({ title, description, taskName, isDisabled = false, multiple, className, options, value = [], onChange, other, isQuiz, error, ...rest }) => {
    const [currentValue, setCurrentValue] = useState(Array.isArray(value) ? value : [value]);
    const type = multiple ? 'checkbox' : 'radio';
    const inputRef = useRef(null);
    const onClick = (value) => {
        if (isDisabled) {
            return;
        }
        if (multiple) {
            const newValues = currentValue.filter(v => v !== value);
            const isAdded = currentValue.includes(value);
            setCurrentValue(isAdded ? newValues : [...newValues, value]);
            return;
        }
        setCurrentValue([value]);
    };
    /**
     * We should avoid our component to call the parent event when we focus in the input element.
     * This is a workaround to avoid the event propagation.
     */
    const onInputClick = e => {
        if (e.target.closest('[data-propagation="off"]')) {
            return;
        }
        onClick('other');
    };
    const isCustomSelected = currentValue.includes('other');
    const customState = isCustomSelected ? 'on' : 'off';
    const prepare = useCallback(() => {
        return currentValue.map(v => {
            if (v === 'other') {
                return inputRef.current?.value || '';
            }
            return v;
        });
    }, [currentValue]);
    useEffect(() => {
        onChange?.(prepare());
    }, [currentValue]);
    const sendChangedValues = () => {
        if (!isCustomSelected) {
            return;
        }
        onChange?.(prepare());
    };
    const pollType = isQuiz ? 'quiz' : 'poll';
    return (_jsxs(TaskCard, { title: taskName, "data-testid": pollType, type: pollType, "data-is-disabled": isDisabled, ...rest, className: cn([containerStyle, className]), children: [(title || description) && (_jsxs("div", { className: infoStyle, children: [title && _jsx("span", { className: titleStyle, children: title }), description && _jsx("span", { className: descriptionStyle, children: description })] })), _jsxs("div", { className: "@xl:grid @xl:grid-cols-2 flex flex-col gap-200", children: [options.map(option => {
                        const selected = currentValue.includes(option.value);
                        return (_jsx(Tag, { disabled: isDisabled, size: "lg", "data-state": selected ? 'on' : 'off', ...option, type, selected, onClick: () => onClick(option.value) }));
                    }), other && (_jsxs(Anatomy.Root, { disabled: isDisabled, selected: isCustomSelected, size: "lg", "data-state": customState, type, onClick: onInputClick, children: [_jsx(Anatomy.LeftIcon, {}), _jsx("input", { ref: inputRef, "data-propagation": "off", className: styles.input({ size: 'lg' }), onChange: sendChangedValues, placeholder: "Custom option..." })] }))] }), error && _jsx("p", { className: formControlStyles.hint({ state: 'error', size: 'lg' }), children: error })] }));
};
