'use client';

import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import type { FileUploadItem, FileUploadProps } from '@zealy/design-system';

import { FileUpload } from '#components/FileUpload';

import type { ClaimTaskInput } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

const IMAGE_TYPES = [
  'image/bmp',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/avif',
];

const MEDIA_TYPES = {
  audio: ['audio/mpeg', 'audio/x-wav', 'audio/ogg', 'audio/webm', 'audio/flac'],
  video: [
    'video/mpeg',
    'video/mp4',
    'video/quicktime',
    'video/x-msvideo',
    'video/webm',
    'video/ogg',
    'video/x-matroska',
  ],
  image: IMAGE_TYPES,
  document: [
    'text/plain',
    'text/richtext',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // Added document types
    'application/rtf',
    'application/vnd.oasis.opendocument.text',
  ],
  presentation: [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.oasis.opendocument.presentation',
  ],
  spreadsheet: [
    'application/vnd.ms-excel',
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet',
  ],
  drawing: [
    'image/vnd.adobe.photoshop',
    'application/vnd.adobe.illustrator',
    // Adobe InDesign
    'application/x-indesign',
    // Encapsulated PostScript
    'application/eps',
    // Sketch
    'application/x-sketch',
  ],
  // TODO to remove after PDF-removal migration is finished
  pdf: [],
  archive: [
    // Added compression and archiving formats
    'application/zip',
    'application/x-rar-compressed',
    'application/x-7z-compressed',
  ],
} as const;

const type = 'file' as const;
type Task = typeof type;

const FileUploadWrapper = withQuest<FileUploadProps, Task>(({ task, ...props }) => {
  const t = useTranslations();
  const { control, formState } = useFormContext<Record<string, ClaimTaskInput<Task>>>();

  const { field } = useController({
    name: task.id,
    control,
    defaultValue: {
      taskId: task.id,
      fileUrls: [],
      type,
    },
  });

  const TASK_TITLE = t('quest.type.file.label');

  const fileTypes = useMemo(() => {
    if (task.settings.restrictFileType) {
      const asArray = Array.from(task.settings.fileTypes || []);
      return asArray.flatMap(x => MEDIA_TYPES[x]);
    }
    return undefined;
  }, [task]);

  const onChange = (items: FileUploadItem[]) => {
    const validatedItems = items.filter(x => !x.error);

    field.onChange({
      ...field.value,
      fileUrls: validatedItems.map(x => x.url),
    });
  };

  const totalOfUrls = field.value.fileUrls.length;
  const shouldShowEmptyState = totalOfUrls < task.settings.maxFileCount;

  return (
    <FileUpload
      title={TASK_TITLE}
      sizeLimit={task.settings.maxFileSize}
      limit={task.settings.maxFileCount}
      fileTypes={fileTypes}
      keepEmptyStateVisible={shouldShowEmptyState}
      {...props}
      onChange={onChange}
      error={formState.errors?.[task.id]?.message}
    />
  );
});

export default FileUploadWrapper;
