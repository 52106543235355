import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';

import type { InviteTaskProps } from '@zealy/design-system';
import type { CommunityMember } from '@zealy/queries';
import { InviteTask, Link } from '@zealy/design-system';
import { ExternalLink2Line } from '@zealy/icons';
import { useAuthenticatedUser, useCommunity, useQuest, useReferralLink } from '@zealy/queries';

import { envConfig } from '#app/config';
import { useError } from '#app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/FormError.context';
import { useCommunityURL } from '#hooks/useCommunityURL';
import { InvitesModal } from '#views/InvitesModal';

import type { ClaimTaskInput } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

const type = 'invites' as const;
type Task = typeof type;

export const generateInvite = (
  baseURL: ReturnType<typeof useCommunityURL>,
  id?: string,
  questId?: string,
): string => {
  if (!id?.length) return '';

  const url = new URL(`${envConfig.appUrl}${baseURL}/invite/${id}`);

  if (questId) {
    url.searchParams.set('questId', questId);
  }

  return url.toString();
};

const Invites = withQuest<InviteTaskProps, Task>(({ task }) => {
  const t = useTranslations('quest.type.invites');
  const tx = useTranslations('invites-modal');
  const { subdomain } = useParams<{ subdomain: string }>();
  const mandatoryQuestId = task.settings.mandatoryQuests?.[0];
  const { data: referralData } = useReferralLink();
  const { data: user } = useAuthenticatedUser<CommunityMember>();
  const [showInvites, setShowInvites] = useState(false);
  const { formState } = useFormContext<Record<string, ClaimTaskInput<Task>>>();

  const communityQuery = useCommunity(subdomain);
  const mandatoryQuestQuery = useQuest(mandatoryQuestId);
  const community = communityQuery.data;
  const mandatoryQuest = mandatoryQuestQuery.data;
  const baseUrl = useCommunityURL();

  const { setError, clearError } = useError(task.id);

  useController({
    name: task.id,
    defaultValue: {
      taskId: task.id,
      type,
    },
  });

  const validInvites = user?.invites?.filter(invite => invite.status === 'active').length ?? 0;

  useEffect(() => {
    const isValid = validInvites >= task.settings.minInviteUserCount;
    if (!isValid) setError(t('errors.not-enough', { min: task.settings.minInviteUserCount }));
    else clearError();

    return clearError;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task.settings.minInviteUserCount, validInvites]);

  const inviteLink = generateInvite(baseUrl, referralData?.id ?? '', mandatoryQuestId);

  const max = task.settings.minInviteUserCount || 0;

  const showInvitesModal = () => setShowInvites(true);
  const hideInvitesModal = () => setShowInvites(false);
  const onShowInvites = () => showInvitesModal();

  const howToItems: Array<{ title: ReactNode; description: ReactNode }> = [
    {
      title: tx(`howto.items.recruit.title`),
      description: tx(`howto.items.recruit.description`),
    },
    {
      title: tx(`howto.items.threshold.title`, {
        xp: community?.minimumRequiredXp,
      }),
      description: tx(`howto.items.threshold.description`, {
        xp: community?.minimumRequiredXp,
      }),
    },
  ];

  if (mandatoryQuest && subdomain) {
    howToItems.push({
      title: tx(`howto.items.mandatoryQuest.title`, { questName: mandatoryQuest.name }),
      description: tx.rich(`howto.items.mandatoryQuest.description`, {
        questName: mandatoryQuest.name,
        ExternalLink: child => (
          <Link
            href={`/cw/${subdomain}/questboard/${mandatoryQuest.categoryId}/${mandatoryQuest.id}`}
            className="inline-flex items-center"
            target="_blank"
            rightIcon={<ExternalLink2Line className="w-icon-xs h-icon-xs" />}
          >
            {child}
          </Link>
        ),
      }),
    });
  }

  return (
    <>
      <InviteTask
        value={validInvites}
        inviteLink={inviteLink}
        max={max}
        onShowInvites={onShowInvites}
        title={t('label')}
        error={formState.errors?.[task.id]?.message}
        baseURL={baseUrl}
        steps={howToItems}
      />
      <InvitesModal
        userId={user?.id}
        open={showInvites}
        mandatoryQuest={mandatoryQuestQuery.data}
        onOpenChange={open => {
          if (!open) hideInvitesModal();
        }}
      />
    </>
  );
});

export default Invites;
