import { useWatch } from 'react-hook-form';

import { InviteTask } from '@zealy/design-system';
import { useReferralLink } from '@zealy/queries';

import { generateInvite } from '#components/Tasks/components/Invites';
import { useCommunityURL } from '#hooks/useCommunityURL';

export const InvitesPreview = ({ index }: { index: number }) => {
  const settings = useWatch({
    name: `tasks.${index}.settings`,
  });
  const baseURL = useCommunityURL();
  const { data: referralData } = useReferralLink();
  const inviteLink = generateInvite(baseURL, referralData?.id ?? '', settings.mandatoryQuests?.[0]);

  return <InviteTask inviteLink={inviteLink} max={settings?.minInviteUserCount} baseURL={baseURL} />;
};
