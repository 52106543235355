'use client';

import { useCommunity } from '@zealy/queries';
import { getCommunityPath } from '@zealy/utils';

export const useCommunityURL = () => {
  const { data } = useCommunity();

  return data ? getCommunityPath(data) : '';
};
