'use client';

import React, { useMemo, useState } from 'react';
import { useFormatter, useTranslations } from 'next-intl';
import Link from 'next/link';
import { useParams } from 'next/navigation';

import { Avatar, Skeleton, TabItem, Tabs, Tag } from '@zealy/design-system';
import { useCommunity, useInvites } from '@zealy/queries';

import { Modal } from '#components/Modal';

import type { InvitesModalProps } from './InvitesModal.types';
import { SELECT_OPTIONS } from './InvitesModal.constants';

interface InviteProps {
  name: string | null;
  avatar: string | null;
  userId: string;
  status: 'pending' | 'consumed' | 'active';
  joinedAt: string;
  xp: number;
}

const Invite = ({ name, avatar, userId, joinedAt, xp }: InviteProps) => {
  const { subdomain } = useParams();
  const t = useTranslations('invites-modal');
  const format = useFormatter();

  return (
    <Link
      href={`/cw/${subdomain}/users/${userId}`}
      className="flex gap-200 items-center focus:outline-none"
    >
      <Avatar src={avatar} name={name} />
      <div className="flex-1">
        <p className="body-component-md-bold text-primary">{name}</p>
        <p className="body-component-md text-secondary">
          {t('join-date', {
            date: format.dateTime(new Date(joinedAt), {
              day: 'numeric',
              month: 'short',
            }),
          })}
        </p>
      </div>

      <Tag size="xs">{parseInt(`${xp}`)} XP</Tag>
    </Link>
  );
};

const InviteSkeleton = () => (
  <div className="flex gap-200 items-center">
    <Skeleton className="h-avatar-md w-avatar-md" />
    <div className="flex-1">
      <Skeleton className="h-200 w-1000 mb-50" />
      <Skeleton className="h-150 w-600" />
    </div>

    <Skeleton className="h-300 w-600" />
  </div>
);

const flattenData = (data?: { pages?: { results: InviteProps[] }[] }) =>
  data?.pages?.flatMap(page => page.results) ?? [];

export const InvitesModal = ({ children, userId, mandatoryQuest, ...props }: InvitesModalProps) => {
  const t = useTranslations('invites-modal');
  const { subdomain, questId } = useParams<{ subdomain?: string; questId?: string }>();

  const [status, setStatus] = useState<InviteProps['status']>(SELECT_OPTIONS[0]);

  const community = useCommunity();

  const { data, isLoading, isFetching, fetchNextPage } = useInvites({
    userId,
    status,
    invitesQuestId: questId,
  });

  const invites = useMemo(() => flattenData(data), [data]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;

    const hasNextPage = data?.pages?.[data.pages.length - 1]?.nextPage;

    if (!isFetching && hasNextPage && bottom) {
      fetchNextPage();
    }
  };

  return (
    <Modal
      title={t('title')}
      trigger={children}
      className="h-[80vh] max-w-[500px] w-full p-300"
      {...props}
    >
      <Tabs orientation="horizontal" value={status}>
        {SELECT_OPTIONS.map(option => (
          <TabItem key={option} value={option} onClick={() => setStatus(option)}>
            {t(`status.${option}`)}
          </TabItem>
        ))}
      </Tabs>
      <div
        className="flex flex-col gap-300 py-300 min-h-0 overflow-y-auto no-scrollbar"
        onScroll={handleScroll}
      >
        {invites?.map(invite => (
          <Invite key={invite.userId} {...invite} />
        ))}

        {(isLoading || isFetching) && (
          <>
            <InviteSkeleton key={0} />
            <InviteSkeleton key={1} />
            <InviteSkeleton key={2} />
          </>
        )}

        {!isLoading && invites?.length === 0 && (
          <p className="text-secondary body-component-md mx-auto">No invites</p>
        )}
      </div>
    </Modal>
  );
};
