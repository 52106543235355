import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { TextField, TwitterSpaceCard } from '@zealy/design-system';

import { useError } from '#app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/FormError.context';

import type { ClaimTaskInput, QuestTask } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

type WrapperProps = Extract<QuestTask, { type: 'twitterSpace' }>;

export const TwitterSpace = ({ task, error }: { task: WrapperProps; error?: string }) => {
  const t = useTranslations('quest.type.twitterSpace.fields.password');
  const { register } = useFormContext<Record<string, ClaimTaskInput<'twitterSpace'>>>();

  const goToSpace = () => {
    window.open(task.settings.spaceUrl, '_blank');
  };

  return (
    <TwitterSpaceCard
      spaceUrl={task.settings.spaceUrl}
      metadata={task.metadata}
      className="w-full"
      onClickListenLive={goToSpace}
      onClickPlayRecording={goToSpace}
      onClickSetReminder={goToSpace}
    >
      {task.settings?.protected && (
        <TextField
          label={t('label')}
          placeholder={t('placeholder')}
          hint={error ?? t('description')}
          isInvalid={!!error}
          {...register(`${task.id}.password`)}
        />
      )}
    </TwitterSpaceCard>
  );
};

export const TwitterSpaceClaim = withQuest<unknown, 'twitterSpace'>(({ task }) => {
  const t = useTranslations('quest.type.twitterSpace.fields.password');
  const { watch } = useFormContext<Record<string, ClaimTaskInput<'twitterSpace'>>>();

  useController({
    name: task.id,
    defaultValue: {
      taskId: task.id,
      type: 'twitterSpace' as const,
    },
  });

  const password = watch(`${task.id}.password`);

  const { clearError, error, setError } = useError(task.id, '');

  useEffect(() => {
    console.log('field.value', password);

    if (task.settings.protected) {
      if (password) {
        clearError();
      } else if (!error) {
        setError('Password is required');
      }
    }
    return () => {
      clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, task.settings.protected]);

  return <TwitterSpace task={task} error={error} />;
});
