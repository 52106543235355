import { generateNonce, verifySignedMessageForNetwork } from '@zealy/queries';
import { SiwsMessage } from '@zealy/utils';

interface AuthenticationAdapter {
  getNonce: () => Promise<string>;
  createMessage: (params: { nonce: string; address: string }) => SiwsMessage;
  getMessageBody: (params: { message: SiwsMessage }) => string;
  verify: (params: { message: SiwsMessage; signature: string }) => Promise<boolean>;
  signOut: () => Promise<void>;
}

export const authenticationAdapter = (onSuccess?: () => void): AuthenticationAdapter => ({
  getNonce: generateNonce,
  createMessage: ({ nonce, address }) => {
    return new SiwsMessage({
      domain: window.location.host,
      address,
      statement: 'Sign in to Zealy',
      uri: window.location.origin,
      version: '1',
      nonce,
    });
  },
  getMessageBody: ({ message }) => {
    return message.prepareMessage();
  },
  verify: async ({ message, signature }) => {
    try {
      await verifySignedMessageForNetwork(signature, message, 'sol-mainnet');
      onSuccess?.();
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  signOut: async () => {},
});
